import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getImageUrl } from "src/common/function";
import TokenService from "../Services/token.service";

function Materials() {
  const [name, setName] = useState("");

  useEffect(() => {
    const userData = TokenService.getUser();
    console.log("userData: ", userData);
    setName(userData.fullName);
  }, []);

  return (
    <div className="w-[1000px] ml-auto mr-auto mt-[100px] flex flex-col gap-5">
      <p className="font-helveticaRoman text-black text-[1.7375em] text-center mb-[50px]">
        Welcome {name}. We invite you to review the following materials. <br />
        If you have any questions, please contact us at info@admiral.trade.
      </p>

      <Link to="/financing/introductoryvideo" target="_blank">
        <div className="decoration-default-color underline ml-[5px] cursor-pointer">
          <p className="font-helveticaRoman text-default-color text-[1.7375em] text-center">Introductory video</p>
        </div>
      </Link>

      <a href={getImageUrl("/pdfs/download.pdf")} target="_blank">
        <div className="decoration-default-color underline ml-[5px] cursor-pointer">
          <p className="font-helveticaRoman text-default-color text-[1.7375em] text-center">
            Description of Admiral Securities (pdf)
          </p>
        </div>
      </a>

      <a href={getImageUrl("/pdfs/download.pdf")} target="_blank">
        <div className="decoration-default-color underline ml-[5px] cursor-pointer">
          <p className="font-helveticaRoman text-default-color text-[1.7375em] text-center">Slide deck</p>
        </div>
      </a>

      <a href={getImageUrl("/pdfs/download.pdf")} target="_blank">
        <div className="decoration-default-color underline ml-[5px] cursor-pointer">
          <p className="font-helveticaRoman text-default-color text-[1.7375em] text-center">Offering details</p>
        </div>
      </a>
    </div>
  );
}

export default Materials;
